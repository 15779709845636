<!--  -->
<template>
  <div>
    <div id="operator-menu-box">
      <div class="operator-list css-text-no-select">
        <div
          v-permission="item.permission"
          :id="item.permission"
          v-for="(item, index) in menu"
          :key="index"
          @click="clickItem(item)"
        >
          <div
            class="operator-list-item imgPictureChange"
            :class="{ 'operator-list-cur-item': routePath === item.path }"
          >
            <div class="operator-label clickBox over_one_line">
              <div class="imgPictureChange centerBox">
                <svg-icon
                  :svg-name="`menu-${item.iconName}-select`"
                  :class-name="'svgBox'"
                />
                <svg-icon
                  :svg-name="`menu-${item.iconName}`"
                  :class-name="'svgBox'"
                />
              </div>
              <div class="over_one_line">
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      curItem: {}
    }
  },
  computed: {
    routePath () {
      return this.$route.path
    }
  },
  props: {
    menu: {},
    menuTitle: {}
  },
  watch: {
    menu: {
      immediate: true,
      handler: function () {
        // console.log('this.$router.path', this.$route)
      }
    }
  },
  methods: {
    clickItem (item) {
      this.$router.push({ path: item.path })
    }
  },
  mounted () {
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less"></style>
