import Axios from '@/http'
export default {
  // 批量添加校验
  insertBatchValid(params) {
    return new Promise((resolve, reject) => {
      Axios.post('scrollCheck/scp/insertBatchValid', params, {
        isJSON: true
      }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 批量添加 */
  saveBatch(params) {
    return new Promise((resolve, reject) => {
      Axios.post('scrollCheck/scp/insertBatch', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },

  /* 今日执行情况(人员巡检任务)V2 */
  todayTaskDetail() {
    return new Promise((resolve, reject) => {
      Axios.post('scrollCheck/deviceSct/todayTaskDetail').then((res) => {
        resolve(res)
      })
    })
  },
  /* 巡检轨迹V2 */
  pointTrail(id) {
    return new Promise((resolve, reject) => {
      Axios.post('scrollCheck/deviceSct/pointTrail', { id }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 巡检任务执行情况 */
  taskExecute(params) {
    return new Promise((resolve, reject) => {
      Axios.post('scrollCheck/sct/taskExecute', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 执行情况(人员巡检任务)V2 */
  deviceSctTaskExecute(id) {
    return new Promise((resolve, reject) => {
      Axios.post('scrollCheck/deviceSct/taskExecute', { id }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 查看表单-设备提交的表单 */
  deviceReport(params) {
    return new Promise((resolve, reject) => {
      Axios.post('scrollCheck/sct/deviceReport', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 查看表单-设备提交的表单 */
  deviceReportV2(recordId) {
    return new Promise((resolve, reject) => {
      Axios.post('scrollCheck/deviceSct/deviceReport', { recordId }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  /* 异常任务设备上报-查询 */
  abnormalDeviceReport(params) {
    return new Promise((resolve, reject) => {
      Axios.post('scrollCheck/abnormal/deviceReport', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 异常任务记录解决 */
  dealFinish(recordId) {
    return new Promise((resolve, reject) => {
      Axios.post('scrollCheck/abnormal/scr/dealFinish', { recordId }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  }
}
