/* 路由 */
const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/commonTasks'
  },
  {
    path: '/commonTasks',
    name: 'commonTasks',
    meta: {
      label: '普通任务'
    },
    component: () => import('@/viewsv2/commonTasks/commonTasks')
  },
  {
    path: '/patrolInspectionTask',
    name: 'patrolInspectionTask',
    meta: {
      label: '巡检任务'
    },
    component: () =>
      import('@/viewsv2/patrolInspectionTask/patrolInspectionTask')
  }
]
/* 侧边导航 */
const menu = [
  {
    label: '普通任务',
    iconName: 'tasksOfTheDay',
    permission: 'mission_commonTasks',
    path: '/commonTasks'
  },
  {
    label: '巡检任务',
    iconName: 'electronicPatrol',
    permission: 'mission_patrolInspectionTask',
    path: '/patrolInspectionTask'
  }
]

// 用户引导数据列表
const userGuidanceList = [
  {
    element: '#mission',
    popover: {
      title: '任务',
      description: '向设备下发任务，设备执行操作向平台汇报任务情况。',
      position: 'bottom'
    }
  },
  {
    element: '#mission',
    popover: {
      title: '任务',
      description: '向设备下发任务，设备执行操作向平台汇报任务情况。',
      position: 'bottom'
    }
  }
]

const componentsContent = {
  /* 巡检点 */
  selectMapLocation: 'selectMapLocation_pipingGas',
  // 记得修改回来 @components/electronicPatrol/electronicPatrolDetails
  /* 巡检明细 */
  electronicPatrolDetails: 'electronicPatrolDetails_pipingGas',
  /* 新增任务 */
  addNewTask: 'addNewTask_dispatch',
  /* 新增巡检点 */
  pointOperation: 'pointOperation_dispatch',
  /* 新增巡检路线 */
  routeOperation: 'routeOperation_dispatch',
  /* 巡检任务操作 (添加 || 编辑) */
  taskOperation: 'taskOperation_dispatch',
  /* 异常任务处理异常 */
  handlingException: 'handlingException_dispatch',
  /* 异常任务 人员提交表单 */
  submissionForm: 'submissionForm_dispatch'
}

module.exports = {
  routes,
  menu,
  userGuidanceList,
  componentsContent
}
