<template>
  <div id="app">
    <div class="operator-wrapper r-f">
      <div v-show="menuList.length" class="operator-menu-box r-f-i">
        <menuList :menu="menuList" menuTitle="任务中心"></menuList>
      </div>
      <div class="operator-content r-f-i" style="padding: 30px 40px 0 40px">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="less">
.operator-wrapper {
  justify-content: space-between;
  height: 100%;

  .operator-menu-box {
  }

  .operator-content {
    width: 100%;
    height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
<script type="text/ecmascript-6">
import menuList from '@/components/menu'
const { menu, userGuidanceList } = require(`@/version/${process.env.VUE_APP_BUILD_VERION}.js`)
export default {
  data () {
    return {
      defineStepsList: null,
      userGuidanceIndex: null,
      userGuidanceList: userGuidanceList,
      menuList: []
    }
  },
  components: {
    menuList
  },
  watch: {
    'userGuidance.header': function (v) {
      if (v) {
        this.userGuidanceFn()
      }
    }
  },
  computed: {
    userGuidance () {
      return this.mainStore.state.user.userGuidance
    }
  },
  methods: {
    setPermission () {
      const permissionIds = window.sessionStorage.getItem('permissionIdList') || ''
      let routerPath = null
      const hash = window.location.hash || ''
      const dataList = []
      /* 判断 hash 中的 path */
      const setRouterPath = (path) => {
        if (!routerPath) {
          if (hash.indexOf(path) !== -1) {
            routerPath = path
          }
        }
      }
      /* 整理 menuList 权限数据 */
      menu.forEach((item) => {
        const permission = item.permission
        if (permission && permissionIds.indexOf(permission) !== -1) {
          setRouterPath(item.path)
          dataList.push(item)
        }
      })
      if (!routerPath && dataList.length) {
        routerPath = dataList[0].path
      }
      this.$nextTick(() => {
        if (routerPath) {
          this.$router.push({ path: routerPath })
        } else {
          this.$router.push({ path: '/noPermissions' })
        }
        this.menuList = dataList
        this.userGuidanceFn()
      })
    },
    /* 用户引导 */
    userGuidanceFn () {
      if (this.userGuidanceList && this.userGuidance) {
        if (this.userGuidanceList.length && !this.userGuidance.mission && this.userGuidance.header) {
        this.$deleteConfirmationBox(
          {
            deleteTitleImg: 'tips',
            deleteTitle: '功能介绍',
            submitClass: 'button_function',
            deleteContent: '<div class="c_9DA7B2" style="letter-spacing: 2px">是否需要新手指引<span class="c_fff">任务说明</span>？</div>',
            closeText: '跳 过',
            submitText: '确 定'
          },
          () => {
            const userGuidance = this.mainStore.getters.windowFn.userGuidance
            const defineStepsList = []
            const permissionIdList = JSON.parse(window.sessionStorage.getItem('permissionIdList')) || []
            /* 判断是否有权限 */
            const userGuidanceList = this.userGuidanceList.filter((v) => {
              if (v.permission) {
                return permissionIdList.findIndex(permission => permission === v.permission) !== -1
              } else {
                return true
              }
            })
            const num = userGuidanceList.length
            /* 整理引导数据 */
            userGuidanceList.forEach((row, index) => {
              const item = {
                element: row.element,
                popover: { ...row.popover }
              }
              let li = ''
              for (let i = 0; i < num; i++) {
                li += i === index ? '<li class="select"></li>' : '<li></li>'
              }
              if (index === 0) {
                item.popover.className = 'first-step-class'
                item.onPrevious = () => {
                  userGuidance.preventMove()
                }
              }
              item.popover.description = `<div class="content">${item.popover.description}</div><ul class="centerBox">${li}</ul>`
              item.onHighlightStarted = () => {
                if (row.query && row.path) {
                  this.$router.push({ path: row.path, query: row.query })
                } else {
                  if (row.path) {
                    this.$router.push({ path: row.path })
                  }
                }
              }
              item.onNext = () => {
                if (row.permission && row.permission === 'operator_apkUpgrade') {
                  this.userGuidanceIndex = index + 1
                  userGuidance.defineSteps(this.defineStepsList)
                  this.$nextTick(() => {
                    userGuidance.start(this.userGuidanceIndex)
                  })
                }
              }
              defineStepsList.push(item)
            })
            /* 标记当前引导的板块 */
            this.mainStore.commit('guidanceSectionName', 'mission')
            this.$nextTick(() => {
              this.defineStepsList = defineStepsList
              userGuidance.defineSteps(defineStepsList)
              userGuidance.start()
            })
          },
          () => {
            this.mainStore.dispatch('userGuidanceFn', {
              name: 'mission',
              content: 1
            })
          }
        )
      }
      }
    }
  },
  created () {
    let userInfo = window.sessionStorage.getItem('userInfo')
    if (userInfo) {
      userInfo = JSON.parse(userInfo)
      this.$store.commit('setUserInfo', userInfo)
    }
    this.setPermission()
  }
}
</script>
