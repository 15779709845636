import Axios from '@/http'
import apiHelper from '@/utils/apiHelper'
import Element from 'element-ui'
export default {
  orgTreeWithGroupAndDevice(params) {
    return new Promise((resolve, reject) => {
      Axios.post('organization/orgTreeWithGroupAndDevice', params).then(
        (res) => {
          return resolve(res.data)
        }
      )
    })
  },
  orgTreeWithSimpleDevice(params) {
    return new Promise((resolve, reject) => {
      Axios.post('organization/orgTreeWithSimpleDevice', params).then((res) => {
        return resolve(res.data)
      })
    })
  },
  orgListWithDev3(params) {
    return new Promise((resolve, reject) => {
      Axios.post('organization/orgTree', params).then((res) => {
        return resolve(res.data)
      })
    })
  },
  orgListWithNotAuthDevNum(params) {
    // 队伍管理查询
    return new Promise((resolve, reject) => {
      Axios.post('organization/orgListWithNotAuthDevNum', params).then(
        (res) => {
          return resolve(res)
        }
      )
    })
  },
  insertGroup(params) {
    // 新增群组
    return new Promise((resolve, reject) => {
      Axios.post('organization/insertGroup', params, { isJSON: true }).then(
        (res) => {
          return resolve(res)
        }
      )
    })
  },
  insertOrganization(params) {
    // 新增部门
    return new Promise((resolve, reject) => {
      Axios.post('organization/insertOrganization', params, {
        isJSON: true
      }).then((res) => {
        return resolve(res)
      })
    })
  },
  updateGroup(params) {
    // 更新组织
    return new Promise((resolve, reject) => {
      Axios.post('organization/updateGroup', params, { isJSON: true }).then(
        (res) => {
          return resolve(res)
        }
      )
    })
  },
  updateOrganization(params) {
    // 更新部门
    return new Promise((resolve, reject) => {
      Axios.post('organization/updateOrganization', params, {
        isJSON: true
      }).then((res) => {
        return resolve(res)
      })
    })
  },
  deleteOrganization(params) {
    // 删除部门
    apiHelper.delConfirm({
      onOk: () => {
        Axios.post('organization/deleteOrganization', { id: params }).then(
          (res) => {
            Element.Message({
              type: 'success',
              message: '删除成功 !'
            })
          }
        )
      }
    })
  },
  deleteOrganizationV2(params) {
    // 删除部门
    return new Promise((resolve, reject) => {
      Axios.post('organization/deleteOrganization', { id: params }).then(
        (res) => {
          return resolve(res)
        }
      )
    })
  },
  deleteGroup(params) {
    // 删除队伍
    return new Promise((resolve, reject) => {
      Axios.post('organization/deleteGroup', { id: params }).then((res) => {
        return resolve(res)
      })
    })
  },
  findAllOrganization(params) {
    // 队伍管理查询
    return new Promise((resolve, reject) => {
      Axios.post('organization/findAll', params).then((res) => {
        return resolve(res)
      })
    })
  },
  groupTree(params) {
    // 队伍管理查询
    return new Promise((resolve, reject) => {
      Axios.post('orgGroup/groupTree').then((res) => {
        return resolve(res)
      })
    })
  },
  findAllOrg(params) {
    // 查找部门
    return new Promise((resolve, reject) => {
      Axios.post('organization/findAllOrg').then((res) => {
        return resolve(res)
      })
    })
  },
  orgList(params) {
    // 查找部门
    return new Promise((resolve, reject) => {
      Axios.post('organization/orgList').then((res) => {
        return resolve(res)
      })
    })
  },
  orgTreeSimple(params) {
    return new Promise((resolve, reject) => {
      Axios.post('/organization/orgTreeSimple', params).then((res) => {
        resolve(res)
      })
    })
  },
  orgDetailFn(params) {
    // 根据id查询部门详情
    return new Promise((resolve, reject) => {
      Axios.post('organization/orgDetail', params).then((res) => {
        return resolve(res)
      })
    })
  },
  orgListWithDeviceUser(params) {
    // 部门树形列表(包含部门人员)
    return new Promise((resolve, reject) => {
      Axios.post('organization/orgListWithDeviceUser', params).then((res) => {
        return resolve(res)
      })
    })
  }
}
