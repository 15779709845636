import Axios from '@/http'

export default {
  /* 获取离线地图信息 */
  bigMapConfig() {
    return new Promise((resolve, reject) => {
      Axios.post('common/bigMapConfig').then((res) => {
        resolve(res)
      })
    })
  },
  /* 根据名称获取poi */
  getPoiByNameFn(params) {
    return new Promise((resolve, reject) => {
      Axios.post('common/getPoiByName', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 地图设置 */
  mapSettingInfo(params) {
    return new Promise((resolve, reject) => {
      Axios.post('common/mapSettingInfo', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 报警类型 */
  commonWarningType(params) {
    return new Promise((resolve, reject) => {
      Axios.post('/common/warningType', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 设备故障类型 */
  commonDeviceFaultCode(params) {
    return new Promise((resolve, reject) => {
      Axios.post('/common/deviceFaultCode', params).then((res) => {
        resolve(res)
      })
    })
  }
}
