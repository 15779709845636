import Axios from '@/http'
export default {
  assignTaskDetail(params) {
    return new Promise((resolve, reject) => {
      Axios.post('taskAssign/assignTaskDetail', params).then((res) => {
        resolve(res)
      })
    })
  },
  todayTaskDetail(params) {
    return new Promise((resolve, reject) => {
      Axios.post('taskAssign/todayTaskDetail', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 任务情况V2（今日的任务执行情况） */
  todayTaskDetailv2(params) {
    return new Promise((resolve, reject) => {
      Axios.post('taskAssign/v2/todayTaskDetail', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 执行情况V2 */
  taskItemViewFn(params) {
    return new Promise((resolve, reject) => {
      Axios.post('taskAssign/v2/taskItemView', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 分配执行人V2 */
  assignUserv2(params) {
    return new Promise((resolve, reject) => {
      Axios.post('taskAssign/v2/assignUser', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  /* 修改任务执行状态 */
  updateStatus(params) {
    return new Promise((resolve, reject) => {
      Axios.post('taskAssign/updateStatus', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  updateStatusv2(params) {
    return new Promise((resolve, reject) => {
      Axios.post('taskAssign/v2/updateStatus', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 巡检任务-状态编辑V2 */
  sctUpdateStatus(params) {
    return new Promise((resolve, reject) => {
      Axios.post('scrollCheck/sct/updateStatus', params).then((res) => {
        resolve(res)
      })
    })
  }
}
