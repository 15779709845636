import Vue from 'vue'
import VueRouter from 'vue-router'
import '../public-path'
const { routes } = require(`@/version/${process.env.VUE_APP_BUILD_VERION}.js`)
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
export default [
  ...routes,
  {
    path: '/noPermissions',
    name: 'noPermissions',
    meta: {
      label: '403 (无权限)'
    },
    component: () => import('@/views/noPermissions/noPermissions')
  }
]
