/* 输入框相关校验方法 */
// import Element from 'element-ui'
// 格式化日期，如月、日、时、分、秒保证为2位数
function formatNumber (n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}
export default {
  install (Vue) {
    Vue.prototype.checkDeviceTypeFn = function (deviceType, onlineState) {
      // [设备类型]{1:执法记录仪;2:对讲机;3:消防调度台;4:消防值班台;5:Phone APP;6:布控球;7:IPC摄像头;8:头盔;9:手电筒;10:工牌;11:无人机;12:车载云台;}
      const deviceTypeList = [
        {
          type: 1,
          svgName: 'lawEnforcement'
        },
        {
          type: 3,
          svgName: 'dispatchingDesk'
        },
        {
          type: 4,
          svgName: 'dutyDesk'
        },
        {
          type: 5,
          svgName: 'phone'
        },
        {
          type: 6,
          svgName: 'controlTheBall'
        },
        {
          type: 7,
          svgName: 'ipc'
        },
        {
          type: 8,
          svgName: 'safetyHat'
        },
        {
          type: 9,
          svgName: 'flashlight'
        },
        {
          type: 10,
          svgName: 'workCard'
        },
        {
          type: 11,
          svgName: 'vehicle'
        },
        {
          type: 12,
          svgName: 'uav'
        },
        {
          type: 13,
          svgName: 'ipc'
        },
      ]
      const index = deviceTypeList.findIndex(v => v.type === deviceType)
      if (index !== -1) {
        return onlineState === 1 ? deviceTypeList[index].svgName : `${deviceTypeList[index].svgName}_offLine`
      } else {
        return onlineState === 1 ? 'walkieTalkie' : 'walkieTalkie_offLine'
      }
    }
    Vue.prototype.formatTimeFn = function (number, format) {
      const time = new Date(number)
      const newArr = []
      const formatArr = ['Y', 'M', 'D', 'day', 'h', 'm', 's']
      const arrWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      newArr.push(time.getFullYear())
      newArr.push(formatNumber(time.getMonth() + 1))
      newArr.push(formatNumber(time.getHours()))
      newArr.push(arrWeek[time.getDay()])
      newArr.push(formatNumber(time.getDate()))
      newArr.push(formatNumber(time.getMinutes()))
      newArr.push(formatNumber(time.getSeconds()))
      newArr.push(formatNumber(time.getDay()))
      for (const i in newArr) {
        format = format.replace(formatArr[i], newArr[i])
      }
      return format
    }
    Vue.prototype.transformation = function (num) {
      const changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'] // changeNum[0] = "零"
      const unit = ['', '十', '百', '千', '万']
      num = parseInt(num)
      const getWan = (temp) => {
        const strArr = temp.toString().split('').reverse()
        let newNum = ''
        for (var i = 0; i < strArr.length; i++) {
          newNum = (i === 0 && strArr[i] === 0 ? '' : (i > 0 && strArr[i] === 0 && strArr[i - 1] === 0 ? '' : changeNum[strArr[i]] + (strArr[i] === 0 ? unit[0] : unit[i]))) + newNum
        }
        return newNum
      }
      const overWan = Math.floor(num / 10000)
      let noWan = num % 10000
      if (noWan.toString().length < 4) noWan = '0' + noWan
      return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
    }
    Vue.prototype.methodTypeTextFn = function (methodType) {
      switch (methodType) {
        case 'ringing':
          return '响铃中'
        case 'answer':
          return '接听中'
        case 'hangup':
          return '对方挂断'
        case 'joinTheRoom':
          return '等待连接画面'
        case 'successfulCall':
          return '连接成功'
        case 'clientClosed':
          return '对方房间'
        case 'exitRoom':
          return '房间关闭'
        case 'suspendRinging':
          return '停止响铃'
      }
    }
    /* createUniqueId 生成随机字符串 */
    Vue.prototype.createUniqueId = function (n) {
      var random = function () { // 生成10-12位不等的字符串
        return Number(Math.random().toString().substr(2)).toString(36) // 转换成十六进制
      }
      var arr = []
      function createId () {
        var num = random()
        var _bool = false
        arr.forEach(v => {
          if (v === num) _bool = true
        })
        if (_bool) {
          createId()
        } else {
          arr.push(num)
        }
      }
      var i = 0
      while (i < n) {
        createId()
        i++
      }
      return arr[0]
    }
    /* enterNumbersRrLetters 只能输入数字和字母
        * compareName 不能大于 compareName
        * message 提示内容
    *  */
    Vue.prototype.enterNumbersRrLetters = function (row, name, content) {
      var replace = /[^\w]/g
      if (content === 1) {
        replace = /[^\da-z]/g
      }
      if (row[name] && row[name].length !== 0 && row[name] !== null) {
        row[name] += ''
        row[name] = row[name].replace(replace, '')
        row[name] = row[name].replace('.', '')
      }
    }
    /* enterTheMumber 只能输入数字
        * compareName 不能大于 compareName
        * message 提示内容
    *  */
    Vue.prototype.enterTheMumber = function (row, name) {
      if (row[name] && row[name].length !== 0 && row[name] !== null) {
        row[name] += ''
        // eslint-disable-next-line no-redeclare,no-useless-escape
        row[name] = row[name].replace(/[^\.\d]/g, '')
        row[name] = row[name].replace('.', '')
      }
    }
    /* keepTwoDecimalPlaces 保留两位小数 */
    Vue.prototype.keepTwoDecimalPlaces = function (value) {
      // eslint-disable-next-line no-redeclare
      var value = Math.round(parseFloat(value) * 100) / 100
      var xsd = value.toString().split('.')
      if (xsd.length === 1) {
        value = value.toString() + '.00'
        return value
      }
      if (xsd.length > 1) {
        if (xsd[1].length < 2) {
          value = value.toString() + '0'
        }
        return value
      }
    }
    /* filterSpaces 过滤空格 */
    Vue.prototype.filterSpaces = function (row, name, content) {
      if (row[name] && row[name].length !== 0 && row[name] !== null) {
        row[name] += ''
        row[name] = row[name].replace(/[` ]/g, '')
        row[name] = row[name].replace(/\s/g, '')
      }
    }
    /* inputForbidden 禁止输入的内容 */
    Vue.prototype.inputForbidden = function (row, name, content) {
      var replace = /[`~!@#$%^&+=<>?:"{}|,./;'\\[\]·~！@#￥%……&+={}|《》？：“”【】、；‘’，。、]/g
      if (content === 1) {
        replace = /[`~!@#$%^&+=<>?:"{}|,/;'\\[\]·~！@#￥%……&+={}|《》？：“”【】、；‘’，。、]/g
      }
      if (row[name] && row[name].length && row[name] !== null) {
        row[name] += ''
        row[name] = row[name].replace(replace, '')
        row[name] = row[name].replace(/\s/g, '')
      }
    }
    /* 可以输入负数 和 小数 */
    Vue.prototype.onlyNumber = function (row, name, type) {
      if (row[name] && row[name].length && row[name] !== null) {
        row[name] += ''
        // 得到第一个字符是否为负号
        var t = row[name].charAt(0)
        // 先把非数字的都替换掉，除了数字和.
        // eslint-disable-next-line no-redeclare,no-useless-escape
        row[name] = row[name].replace(/[^\d\.]/g, '')
        // 必须保证第一个为数字而不是.
        row[name] = row[name].replace(/^\./g, '')
        // 保证只有出现一个.而没有多个.
        row[name] = row[name].replace(/\.{2,}/g, '.')
        // 保证.只出现一次，而不能出现两次以上
        row[name] = row[name].replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
        // 如果第一位是负号，则允许添加
        if (type !== 1) {
          if (t === '-') {
            row[name] = '-' + row[name]
          }
        }
      }
    }
    /* 文件大小转换 */
    Vue.prototype.publicFileSizeFn = function (size) {
      if (size) {
        if (size < 1024) {
          return size.toFixed(3) + 'B'
        }
        size /= 1024
        if (size < 1024) {
          return size.toFixed(3) + 'KB'
        }
        size /= 1024
        if (size < 1024) {
          return size.toFixed(3) + 'MB'
        }
        size /= 1024
        if (size < 1024) {
          return size.toFixed(3) + 'GB'
        }
        size /= 1024
        return size.toFixed(3) + 'TB'
      } else {
        return '/'
      }
    }
    /* 敏感字用***替换 */
    Vue.prototype.sensitiveFontsFn = function (str) {
      return str
      // var beginLen = 1
      // var endLen = -1
      // if (str && str.length > 1) {
      //   if (str.length === 2) {
      //     return str[0] + '*'
      //   } else if (str.length < 6) {
      //     let len = str.length
      //     let firstStr = str.substr(0, beginLen)
      //     let lastStr = str.substr(endLen)
      //     let middleStr = str.substring(beginLen, len - Math.abs(endLen)).replace(/[\s\S]/ig, '*')
      //     let tempStr = firstStr + middleStr + lastStr
      //     return tempStr
      //   } else {
      //     var content = ''
      //     var replace = ''
      //     var number = 6
      //     var moveBack = 0
      //     for (let i = 0; i < number; i++) {
      //       replace += '*'
      //     }
      //     content = str.substr(0, parseInt(str.split('').length / number + moveBack)) + replace + str.substr(parseInt(str.split('').length / number + number), str.split('').length)
      //     return content
      //   }
      // } else {
      //   return str
      // }
    }
    Vue.prototype.sensitiveFontsV2Fn = function (name, number = 6, moveBack = 0) {
      return name
      // if (name) {
      //   let str = name + ''
      //   var content = ''
      //   var replace = ''
      //   if (str.length < 6) number = 2
      //   for (let i = 0; i < number; i++) {
      //     replace += '*'
      //   }
      //   content = str.substr(0, parseInt(str.split('').length / number + moveBack)) + replace + str.substr(parseInt(str.split('').length / number + number), str.split('').length)
      //   return content
      // } else {
      //   return name
      // }
    }
    /* 敏感字用***替换 */
    Vue.prototype.sensitiveFontsListFn = function (IdList, type) {
      // var content = ''
      return IdList
      // if (type === 1) {
      //   if (IdList) {
      //     var arr = IdList.split('-')
      //     arr[0] = this.sensitiveFontsFn(arr[0])
      //     return arr.join('-')
      //   } else {
      //     return '/'
      //   }
      // } else {
      //   if (IdList) {
      //     var arrV2 = IdList.split(',')
      //     arrV2.forEach(v => {
      //       content += this.sensitiveFontsFn(v) + ','
      //     })
      //     return content.substring(0, content.length - 1)
      //   } else {
      //     return '/'
      //   }
      // }
    }
  }
}
